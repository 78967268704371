<template>
  <section class="pb-4 container">
    <GoBack />
    <Breadcrumb class="sobre_section_bc-color">
      <template v-slot:breadcrumbLinks>
        <router-link
          :style="{
            color: color,
          }"
          tag="p"
          :to="{ name: link.href }"
          v-for="(link, idx) in breadcrumbItems"
          :key="idx"
        >
          {{ link.text }}
        </router-link>
      </template>
    </Breadcrumb>
    <v-row>
      <v-col class="d-flex justify-center align-center mt-3 ml-3">
        <FlagTitle title="Sistematização e dossiê" />
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center align-center mb-5 flex-md-row-reverse">
      <v-col
        cols="12"
        md="6"
        class="d-flex justify-center align-center mx-auto"
      >
        <div class="section__container_card">
          <Card
            title_alignment="center"
            borderColor="#C92F05"
            titleColor="#C92F05"
          >
            <template v-slot:conteudo>
              <p>
                A quinta etapa do processo de cadastramento é a sistematização
                dos danos e perdas imateriais<sup>1</sup> sofridas pelo núcleo
                familiar. É realizada por uma equipe de profissionais com
                formação em Direito, que verifica se houve ameaça ou lesão a
                direitos que não sejam de ordem material, a partir da análise
                minuciosa dos produtos das etapas anteriores. Identifica-se,
                então, quais são suas características, seus respectivos efeitos
                e a consequente relação com o rompimento da barragem de Fundão
                (nexo de causalidade).
              </p>
              <p>
                É feita a estruturação das lesões de cada membro individualmente
                e cada um possui uma tabela na qual os danos são organizados e
                sistematizados, uma vez que são de caráter personalíssimo. Essa
                aferição é realizada principalmente por meio da verificação do
                formulário, da tomada de termo e do relatório de cartografia
                social. A identificação dos danos é realizada por categorias e
                estruturada em tabelas com especificação da lesão e a referência
                do instrumento em que foi localizada. O sistematizador realiza o
                trabalho orientando a leitura detida por perguntas geradoras,
                por exemplo: “Qual a lesão verificada? Quais os efeitos e/ou
                características da lesão?” Para responder, utilizam referências
                diretas aos fragmentos dos produtos analisados que possibilitam
                a identificação e a comprovação do dano incluído, buscando
                valorizar ao máximo o conteúdo da fala da pessoa atingida
                conforme foi relatado nos produtos analisados.
              </p>
              <p>
                Ainda nessa etapa, é considerada a perda dos bens coletivos das
                comunidades: o acesso à escola, ao posto de saúde, aos templos
                religiosos, ao correio, ao transporte público, a praças, campos
                e bares, aos calçamentos, guias e sarjetas, à iluminação pública
                e à telefonia e comunicação.
              </p>
            </template>
          </Card>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <div
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <img
            max-width="100%"
            src="../../assets/sistematizacao_dossie_01.png"
            alt=""
          />
          <p style="font-size: 0.5em">
            Dossiês sistematizados e impressos para serem entregues aos núcleos
            atingidos pelo rompimento da barragem de Fundão. Foto: Acervo
            Cáritas, 2022.
          </p>
        </div>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center align-center mb-5">
      <v-col
        cols="12"
        md="6"
        class="d-flex justify-center align-center mx-auto mb-3"
      >
        <div class="section__container_card">
          <Card borderColor="#C92F05" titleColor="#C92F05">
            <template v-slot:conteudo>
              <p>
                Tal trabalho tem como objetivo esclarecer e facilitar a
                visualização por parte das pessoas atingidas a respeito de todos
                os danos que foram sofridos por elas durante o desastre-crime
                continuado que se perpetua desde o rompimento da barragem. A
                organização dessas lesões com a utilização principalmente de
                fragmentos de falas auxilia no processo de constatação das
                violações e no reconhecimento da situação de atingida e
                atingido, além de contribuir para a conscientização da opressão
                que vivem como sujeitos desse processo de violências.
              </p>
              <p>
                Os produtos elaborados no processo de cadastramento são, então,
                organizados e diagramados em um dossiê familiar, impresso e
                encadernado, e entregues aos núcleos familiares em uma reunião
                que visa detalhar o conteúdo desse relatório, explicar como se
                dão os prosseguimentos e disponibilizar o serviço de Assessoria
                Jurídica da Cáritas para eventuais atendimentos e para o
                acompanhamento sistemático durante as reuniões da Fase de
                Negociação Extrajudicial (FNE).
              </p>
              <p>
                Caso os atingidos e/ou atingidas sintam necessidade de alterar
                ou complementar alguma informação, são realizados atendimentos
                para a elaboração de um documento de retificação. Mediante a
                autorização da família atingida, o dossiê é disponibilizado para
                a Fundação Renova na data que o núcleo familiar considerar
                adequada, o que dá início à contagem dos prazos para o
                recebimento da proposta indenizatória<sup>2</sup>, abertura e
                encerramento da FNE, conforme previsto no Termo de Acordo, de 2
                de outubro de 2018.
              </p></template
            >
          </Card>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <div
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <img
            max-width="100%"
            src="../../assets/sistematizacao_dossie_02.png"
            alt=""
          />
          <p style="font-size: 0.5em">
            Entrega de dossiês pela Cáritas a famílias atingidas pelo rompimento
            da barragem de Fundão. Foto: Acervo Cáritas, 2019.
          </p>
        </div>
      </v-col>
    </v-row>

    <v-row class="container-fluid d-flex mt-5">
      <v-col cols="12" class="d-flex flex-column justify-center align-center">
        <div class="inside__card" style="width: 100%;">
          <p>
            <sup>1</sup> Em um primeiro momento, a quinta etapa do processo de
            cadastramento consistia na leitura atenta de todos os instrumentos
            produzidos pela Cáritas para cada núcleo familiar, no intuito de
            identificar e, posteriormente, sistematizar os danos passíveis de
            indenização, originando um tabelamento incorporado ao dossiê de
            cadastro, a ser instrumentalizado pelo núcleo familiar no momento da
            indenização. Entretanto, com o Termo de Acordo, de outubro de 2018,
            e a possibilidade de um acompanhamento jurídico por parte da
            Assessoria Técnica, a sistematização dos danos materiais e dos
            relacionados às atividades econômicas, em razão de sua natureza,
            passou a não compor o tabelamento.
          </p>
          <p>
            <sup>2</sup> Segundo o Parecer Técnico Jurídico Geral produzido pela
            Assessoria Jurídica da Cáritas, as propostas são apresentadas sempre
            incompletas, de maneira a não corresponderem com os danos
            identificados no processo de cadastramento e materializados no
            dossiê.
          </p>
        </div>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center align-center mb-5 mt-5">
      <div class="d-flex flex-column justify-center align-center">
        <v-btn
          :to="{ name: 'sobre_ati_continuada' }"
          small
          color="#E06919"
          class="white--text pa-5"
        >
          Conhecer "ATI Continuada"
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </div>
    </v-row>
  </section>
</template>

<script>
import Card from "@/components/Card.vue";
import Breadcrumb from "@/layout/Breadcrumb.vue";
import FlagTitle from "../../components/FlagTitle.vue";
import GoBack from "@/components/GoBack.vue";

export default {
  components: { Card, Breadcrumb, FlagTitle, GoBack },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "<       Quem somos       /",
          href: "cards",
        },
        {
          text: "      Assessoria Técnica      /",
          href: "sobre_assessoria_tecnica",
        },
        {
          text: "O Cadastro /",
          href: "sobre_cadastro",
        },
        {
          text: "Sistematização e Dossiê",
          href: "sobre_sistematizacao_dossie",
        },
      ],
    };
  },
};
</script>

<style scoped>
ul,
li {
  font-size: 0.8em;
}

.form__image img {
  max-width: 70%;
}
.card__footer_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: white;
}

.section__container_card {
  max-width: 40em;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.assessoria_card {
  padding: 2em;
}

.assessoria_card p {
  padding: 1em;
  text-align: center;
  font-size: 0.7em;
}

.assessoria__items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 7em;
}

.assessoria__items p {
  text-align: center;
  font-size: 0.6em;
  max-width: 80%;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
